import styled from 'styled-components'

export const Wrapper = styled.div`
  text-align: center;

  @media (max-width: 680px) {
    align-self: center;
    margin-bottom: 2rem;
  }
`

export const Grow = styled.img`
  width: 100px;
  transition: all 0.2s ease-in-out;
  margin: 0 2rem 1rem 2rem;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 360px) and (min-width: 200px) {
    width: 75px;
    margin: 0 1rem 1rem 1rem;
  }
`

export const Title = styled.h4`
  font-weight: normal;
`
