import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { SmallerContainer } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import { MagicalButton, Wrapper, Flex } from './styles'

export const Intro = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Wrapper theme={theme} as={SmallerContainer}>
      <h1>I'm Abdul Ghani</h1>
      <p>
        A self-taught Full stack developer, Software Engineer, and a Blogger.
        <br /> I love building software that solves real-world problems, add
        value to society through technology.
      </p>
      <Flex>
        <MagicalButton
          href="https://www.canva.com/design/DAESbiwWZlo/NoD733TR7gdE9mqGsBPSlA/view?utm_content=DAESbiwWZlo&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton"
          rel="noopener noreferrer"
          target="_blank"
        >
          View resume
        </MagicalButton>
        <MagicalButton as={Link} to="/contact">
          Get In Touch
        </MagicalButton>
      </Flex>
    </Wrapper>
  )
}
