import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
  Container,
  CardPost,
  Row,
  Subtitle,
  CustomButton,
} from 'components/common'
import { Wrapper, Center } from './styles.js'

export const Popular = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPost(
        filter: {
          status: { eq: "publish" }
          categories: { elemMatch: { slug: { eq: "popular" } } }
        }
      ) {
        edges {
          node {
            id
            slug
            status
            title
            excerpt
            date(formatString: "MMMM DD, YYYY")
            modified(formatString: "MMMM DD, YYYY")
            fields {
              readingTime {
                text
                minutes
                time
                words
              }
            }
            categories {
              id
              name
              slug
            }
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <Wrapper as={Container}>
      <Subtitle>Popular articles</Subtitle>
      <Row landing>
        {data &&
          data.allWordpressPost &&
          data.allWordpressPost.edges.map(
            ({
              node: {
                id,
                excerpt,
                timeToRead,
                title,
                slug,
                categories,
                date,
                featured_media,
                fields: {
                  readingTime: { text },
                },
              },
            }) => (
              <CardPost
                landing
                key={id}
                description={excerpt.replace(/<(.|\n)*?>/g, '')}
                timeToRead={timeToRead}
                title={title}
                date={date}
                path={'/' + slug}
                thumbnail={
                  featured_media
                    ? featured_media.localFile.childImageSharp.fluid.originalImg
                    : null
                }
                tags={categories}
              />
            )
          )}
      </Row>
      <Center>
        <CustomButton>
          <Link to="/blog/">See more</Link>
        </CustomButton>
      </Center>
    </Wrapper>
  )
}
